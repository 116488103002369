
.form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 8rem auto 0 auto;
    max-width: 60.2rem;
    transition: all 0.3s ease-in-out;
    
}
.form > div {
    width: 100%;
}
.form h2 {
    font-size: 1.6rem;
    font-weight: 600;
    color: #667c88;
    width: 100%;
    margin-bottom: 2rem;
}

.form .error{
    font-size: 1.4rem;
    color: #f24e4e;
    font-weight: 600;
    margin-bottom: .5rem;
}
.form .group label{
    flex: 1 0 auto;
}

.form hr {
    width: 100%;
    height: 1px;
    margin: 0.9rem 0 2.4rem;
    border: 0;
    background: #ccd3d7;
}
.form label {
    position: relative;
    display: block;


    margin: 1.5rem 1rem;
}
 


.form input[type="password"],
.form input[type="text"],
.form input[type="number"],
.form input[type="email"],
.form select {
    width: 100%;
    height: 4.8rem;
    padding: 0 2.3rem;
    border-radius: 2.4rem;
    border: 1px #002538 solid;
    font-weight: 600;
    background: white;
    color: #333;
    -webkit-appearance: none;
    -moz-appearance: none;
}
div[data-error] .form input[type="password"],
div[data-error] .form input[type="text"],
div[data-error] .form input[type="number"],
div[data-error] .form input[type="email"],
div[data-error] .form select {
   border: 1px #f24e4e solid;
}
.error{
    color: #f24e4e;
    text-align: center;
}

.form label span {
    position: absolute;
    top: 1.3rem;
    left: 2.4rem;
    font-weight: 600;
    color: rgba(51, 51, 51, 0.5);
    transition: all 0.3s ease-in-out;
}
.form input[type="password"]:focus + span,
.form input[type="text"]:focus + span,
.form input[type="number"]:focus + span,
.form input[type="email"]:focus + span,
.form select:focus + span,
.form input:not([value=""]) + span,
.form select:not([data-option=""]) + span {
    top: -0.8rem;
    left: 3rem;
    padding: 0 0.4rem;
    font-size: 1.2rem;
    font-weight: 400;
    background: white;
    color: #333;
}
.form input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 2.4rem;
    height: 2.4rem;
    margin: 0.7rem 0 0 0.7rem;
}
.form input[type="checkbox"] + p {
    position: relative;
    min-height: 2.4rem;
    padding: 0.8rem 0 0.6rem 3.8rem;
}
.form input[type="checkbox"] + p:before,
.form input[type="checkbox"]:checked + p:before {
    content: "";
    position: absolute;
    left: 0.7rem;
    top: 0.7rem;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.5rem;
    border: 1px #002538 solid;
}
.form input[type="checkbox"]:checked + p:before {
    background: url(../img/checkmark-green.svg) center center no-repeat;
    background-size: 1.3rem;
}
.form button,
.purchasesection button {
    height: 4.8rem;
    width: 35rem;
    border-radius: 28px;
    background-color: #008766;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: system-ui, 'Open Sans', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    margin: 2rem auto;
    transition: all 0.3s ease-in-out;
}
.form button:disabled,
.purchasesection button:disabled {
    border: 1px #ccd3d7 solid;
    background: repeating-linear-gradient(
        135deg,
        #fff,
        #fff 0.3rem,
        #ccd3d7 0.5rem,
        #ccd3d7 0.5rem
    );
    color: #c3ccd0;
    cursor: not-allowed;
}
.form input[name="postal_code"] + span:after {
    content: "nr";
}
.form label[data-field="postal_code"] {
    width: 8rem;
}
.form label[data-field="city"] {
    width: 24rem;
}

.form .autofillFix {
    visibility: hidden;
}
.form input:disabled,
.form input:disabled ~ span{
    opacity: 0.4;

}

.form input[data-hidden="true"]{
    width: 0;
    opacity: 0;
    float: left;
    height: 1.3rem;
    padding: 0;
}

@media screen and (min-width: 768px) {
    .form {
        margin: 2rem 0 2rem 0;
    }
    .form input[name="postal_code"] + span:after {
        content: "nummer";
    }
   
    .form label[data-field="postal_code"] {
        width: 10rem;
    }
    .form label[data-field="city"],
    .form label[data-field="terms"] {
        width: 30rem;
    }
    .form .group {
        display: flex;
    }
}

.form .optioninputs h2{
    margin-top: 3rem;
    margin-bottom: -1.5rem;
    font-size: 2rem;
    color: black;

    font-weight: 800
}
.form .optioninputs {
    position: relative;
}
.form .optioninputs:after {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -1rem;
    left: 0;
    width: 100%;
    height: 3px;
    background: white;
}
.form .optioninput {
    display: block;
    margin-bottom: 1.6rem;
}
.form .optioninput input {
    display: none;
}
.form .optioninput div {
    position: relative;
    margin: 0 -1rem;
    padding: 1.9rem 2.8rem 1.8rem 5.7rem;
    border-radius: 10rem;
    background: url(../img/input-radio-unchecked.svg) left 2.1rem center no-repeat;
    background-size: 2rem;
}

.form .optioninput div:before {
    z-index: -2;
    bottom: auto;
    top: -1rem;
    background: white;
}
.form .optioninput h3,
.form .optioninput h4 {
    font-weight: 400;
    margin-right: .8rem;
    margin-top: 0;
    margin-bottom: 0;
}
.form .optioninput h3 {
    font-size: 2rem;
}
.form .optioninput h4 {
    font-size: 1.8rem;
}
.form .optioninput strong {
    font-weight: 900;
}
.form .optioninput p {
    padding-top: 0.4rem;
}
.form .optioninput span {
    display: none;
}
.form .optioninput em {
    position: absolute;
    top: 2.2rem;
    right: 2.8rem;
    font-style: normal;
    color: #333;
}
.form .optioninput a {
    position: absolute;
    top: 2.2rem;
    right: 2.8rem;
}
.form .optioninput input:checked ~ div {
    background-color: #002538;
    background-image: url(../img/input-radio-checked.svg);
    background-size: 2rem;
    color: white;
}
.form .optioninput input:checked ~ div h3,
.form .optioninput input:checked ~ div h4 {
    color: white;
}
.form .optioninput input:checked ~ div em {
    color: inherit;
}
@media screen and (min-width: 768px) {
    .form .optioninput div {
        display: flex;
        align-items: center;
        border-radius: 0;
        border: 1px transparent solid;
        cursor: pointer;
    }
    .form .optioninput div:hover {
        border-radius: 10rem;
        border: 1px #ccd3d7 solid;
        box-shadow: rgba(0,0,0, 0.06) 0 0.9rem 1.4rem;
    }
    .form .optioninput input:checked ~ div {
        border-radius: 10rem;
        border-color: #002538;
    }
    .form .optioninput input:checked ~ div:before,
    .form .optioninput input:checked ~ div:after,
    .form .optioninput div:hover:before,
    .form .optioninput div:hover:after {
        z-index: -1;
        background-color: transparent;
    }
    .form .optioninput input:checked ~ div:before,
    .form .optioninput div:hover:before {
        background-color: white;
    }
    .form .optioninput h3,
    .form .optioninput h4,
    .form .optioninput p,
    .form .optioninput address,
    .form .optioninput span {
        display: inline;
    }
    .form .optioninput p {
        padding-top: 0;
    }
    .form .optioninput p:before {
        content: "- ";
        margin-left: 0.6rem;
    }
    .form .optioninput address {
        margin-left: 1.2rem;
    }
    .form .optioninput span {
        position: absolute;
        right: 12rem;
        font-size: 1.8rem;
    }
    .form .optioninput a {
        right: 3.2rem;
    }
}
